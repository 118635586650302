import {
  BackgroundImage,
  Container,
  FormProvider,
  ImageUpload,
  SelectTag,
  TextField
} from '../../components';
import { aboutUs as aboutUsAssets } from '../../assets';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Button, Stack, Typography } from '@mui/material';

function getTodayDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // getMonth() returns month from 0-11, so we add 1
  const day = String(today.getDate()).padStart(2, '0'); // getDate() returns the day of the month

  return `${year}-${month}-${day}`;
}

const POOL_TYPES = [
  { label: 'In Ground' },
  { label: 'Above Ground' },
  { label: 'Concrete' },
  { label: 'Vinyl' },
  { label: 'Fiber Glass' },
  { label: 'Salt Water' },
  { label: 'Chlorine' }
];
const POOL_SURFACE_CONDITION = [
  { label: 'Good' },
  { label: 'Fair' },
  { label: 'Poor' }
];

export const Inspection = () => {
  const methods = useForm({
    resolver: yupResolver(Yup.object().shape({})),
    defaultValues: {
      date: getTodayDate()
    },
    mode: 'all'
  });

  const { handleSubmit, setValue } = methods;

  const onFormSubmit = async (formValues) => {
    console.log(formValues);
  };

  return (
    <>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Box sx={{ maxWidth: '55rem', mx: 'auto' }}>
          <Typography variant="h3" sx={{ my: 4 }} textAlign="center">
            Swimming pool inspection report
          </Typography>
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onFormSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
          >
            <Stack spacing={2}>
              <TextField name="for" label="Inspection report for" required />
              <TextField
                name="date"
                type="date"
                disabled
                label="Date of Inspection"
                required
              />
              <TextField name="address" label="Property address" required />
              <TextField name="name" label="Inspector name" required />
            </Stack>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                General Information
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  required
                  label="Pool Type"
                  name="poolType"
                  menus={POOL_TYPES}
                />
                <Stack direction="row" spacing={1}>
                  <TextField
                    name="length"
                    containerSX={{ flex: 0.5 }}
                    label="Pool Length"
                    required
                  />
                  <TextField
                    name="width"
                    containerSX={{ flex: 0.5 }}
                    label="Pool Width"
                    required
                  />
                </Stack>
                <Stack direction="row" spacing={1}>
                  <TextField
                    name="depth"
                    containerSX={{ flex: 0.5 }}
                    label="Pool Depth"
                    required
                  />
                  <TextField
                    name="gallons"
                    containerSX={{ flex: 0.5 }}
                    label="Total Gallons"
                    required
                  />
                </Stack>
                <ImageUpload
                  name="generalImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Structural Components
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="poolSurfaceCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Pool Surface Condition"
                />
                <TextField
                  name="poolSurfaceConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="poolDeckCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Pool Deck Condition"
                />
                <TextField
                  name="poolDeckConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="tilesCopingCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Tiles and Coping Condition"
                />
                <TextField
                  name="tilesCopingConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="structuralImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Pool Equipment
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="pumpMotorCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Pump and Motor Condition"
                />
                <TextField
                  name="pumpMotorConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="filterCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Filter Condition"
                />
                <TextField
                  name="filterConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="heaterCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Heater Condition (if applicable)"
                />
                <TextField
                  name="heaterConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="poolLightCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Pool Lights Condition"
                />
                <TextField
                  name="poolLightConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="chlorinatorCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Chlorinator / Saltwater System Condition"
                />
                <TextField
                  name="chlorinatorConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="poolEquipmentImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Plumbing and Electrical Systems
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="plumbingCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Pumbing Condition"
                />
                <TextField
                  name="plumbingConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <SelectTag
                  name="electricalCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Electrical Condition"
                />
                <TextField
                  name="electricalConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="plumbingImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Safety Features
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="fencingCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Fencing / Barriers Condition"
                />
                <TextField
                  name="fencingConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="safetyImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Water Quality and Treatment
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="waterClarityCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Water Clarity Condition"
                />
                <TextField
                  name="waterClarityConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="waterClarityImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Additional Features
              </Typography>
              <Stack spacing={2}>
                <SelectTag
                  name="waterFeatureCondition"
                  menus={POOL_SURFACE_CONDITION}
                  required
                  label="Water Features Condition"
                />
                <TextField
                  name="waterFeatureConditionComment"
                  label="Comment"
                  multiline
                  rows={6}
                />
                <ImageUpload
                  name="additionalImage"
                  label="Upload Image"
                  setValue={setValue}
                />
              </Stack>
            </Box>
            <Box>
              <Typography variant="h5" textAlign="center" gutterBottom>
                Additional Notes
              </Typography>
              <Stack spacing={2}>
                <TextField
                  name="additionalNote"
                  label="Additional Note"
                  multiline
                  rows={6}
                />
              </Stack>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Container>
    </>
  );
};
