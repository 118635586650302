import { useState } from 'react';
import { Button, Stack, styled, Typography, useTheme } from '@mui/material';
import { gallery as galleryAssets } from '../../assets';
import { BackgroundImage, Container } from '../../components';
import { BUTTONS } from './utils';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.black,
  minWidth: 'fit-content',
  flex: 1
}));

export const Gallery = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedGallery, setSelectedGallery] = useState({
    id: BUTTONS[0].id,
    imageCount: BUTTONS[0].imageCount
  });

  return (
    <>
      <Button
        variant="contained"
        onClick={() => navigate('/schedule-now')}
        sx={{
          p: 1.5,
          position: 'fixed',
          zIndex: '10',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          top: '70%',
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
      >
        Schedule Now
      </Button>
      <BackgroundImage
        sx={{
          height: '100vh',
          position: 'relative',
          zIndex: '11',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column'
        }}
        src={galleryAssets.outdoor8}
        alt="Blue Sage Pools"
      >
        <Container
          sx={{ backdropFilter: 'blur(5px)', p: { xs: 2, sm: 4, md: 5 } }}
        >
          <Typography
            color="common.white"
            sx={{ '&::before': { backgroundColor: 'common.white' } }}
            variant="caption"
          >
            Welcome to blue sage pools
          </Typography>
          <Typography variant="h3" color="common.white" sx={{ my: 3 }}>
            We Specialize In Outdoor Structures and Fireplaces
          </Typography>
        </Container>
      </BackgroundImage>
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={2}
          flexWrap="wrap"
        >
          {BUTTONS.map((button) => (
            <StyledButton
              key={button.id}
              variant="contained"
              sx={{
                ...(selectedGallery.id === button.id && {
                  backgroundColor: theme.palette.cyan,
                  color: theme.palette.common.white
                })
              }}
              onClick={() =>
                setSelectedGallery({
                  id: button.id,
                  imageCount: button.imageCount
                })
              }
            >
              {button.label}
            </StyledButton>
          ))}
        </Stack>
        <Stack
          direction="row"
          gap={2}
          sx={{ mt: { xs: 2, sm: 3, md: 5 } }}
          flexWrap="wrap"
        >
          {[...Array(selectedGallery.imageCount).keys()].map((index) => (
            <BackgroundImage
              key={`${selectedGallery.id}${index + 1}`}
              src={galleryAssets[`${selectedGallery.id}${index + 1}`]}
              alt="Gallery"
              sx={{
                height: theme.spacing(50),
                minWidth: '30rem',
                flex: 1
              }}
            />
          ))}
        </Stack>
      </Container>
    </>
  );
};
