import { InputBase, Stack, Typography } from '@mui/material';

const isValidImage = (base64) => {
  const regex = /^data:image\/(jpeg|png);base64,/;
  return regex.test(base64);
};

export const ImageUpload = ({ name, setValue, label, ...props }) => {
  const onChangeHandler = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64Data = e?.target?.result;
        if (isValidImage(base64Data)) {
          setValue(name, base64Data);
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  return (
    <Stack spacing={0.5}>
      <Typography variant="caption2" color="primary.main" sx={{ m: 0 }}>
        {label}
      </Typography>
      <InputBase
        type="file"
        size="small"
        accept=".png, .jpg, .jpeg"
        name={name}
        onChange={onChangeHandler}
        {...props}
      />
    </Stack>
  );
};
