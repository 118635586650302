const CAROUSEL_CONTENT = [
  {
    paragraph: `I normally don't leave a review but - I was taking my water to a local store and kept getting different answers on what to do. These guys knew exactly how to treat water - my pool has never been as clean and controlled as it is today. I travel all the time, so it's nice to finally come home to a pool that is not hazy or smells like chlorine - what a difference.`,
    author: 'Mani, J.'
  },
  {
    paragraph: `Dusty has been in the pool industry for over 35 years. His knowledge, expertise, and work ethic will help you with all your needs in building, servicing, cleaning, and anything for outdoor spaces. Blue Sage is a great company you can count on!`,
    author: 'John, M.'
  },
  {
    paragraph: `What a great company with great owners. You can rely on Blue Sage Pools for superior service and exceptional value. Their experience in the pool industry definitely makes a difference.
You can trust Blue Sage Pools to take care of you.`,
    author: 'Bob, H.'
  },
  {
    paragraph: `I highly recommend Blue Sage Pools for any pool maintenance, pool repairs or outdoor living needs. The team at Blue Sage Pools are veterans in the Pool Industry. They are knowledgeable, trustworthy and provide outstanding service at a highly competitive price.`,
    author: 'Keith, R.'
  },
  {
    paragraph: `If you have a pool, are considering building one someday, or perhaps even thinking about some outdoor structures, check out my friends at BLUE SAGE POOLS. If you need it, they do it: pool maintenance & repair, installation, design & build-out (including outdoor kitchens, gazebos, decks, water features, and more). Blue Sage Pools is founded and operated by my great friend Dusty Hodge. I highly endorse their business and hope you'll consider giving them a try!`,
    author: 'JD, T.'
  },
  {
    paragraph: `Blue Sage Pools is a company you can trust. Their owner is a man of integrity. They will do what they say they will do and get the job done when it's supposed to be done without compromising quality or craftsmanship.`,
    author: 'Patrick, C.'
  },
  {
    paragraph: `Blue Sage Pools has been incredible. They are reliable and efficient. They were very fair with their pricing. What I really love is how they take the time to explain all the issues to you. I highly recommend them!`,
    author: 'Jeff, C.'
  },
  {
    paragraph: `Dusty is amazing and really cares about his clients. He pays such great attention to detail and really works hard to make sure you are satisfied with your project!`,
    author: 'Sarah, T.'
  },
  {
    paragraph: `In this day and age, everyone is searching for the best value they can find in every area of their lives. For pool-related services and needs, you will find the best combination of price and service in the industry. You can't deliver great value unless you have extensive experience, high levels of service, and trustworthy performance. Try my friends at Blue Sage Pools - you will be thoroughly satisfied!`,
    author: 'Phil, S.'
  },
  {
    paragraph: `I have been very impressed with the folks at Blue Sage Pools. They were very professional and knowledgeable regarding my recent pool needs. A pleasure to do business with!`,
    author: 'Roger, S.'
  },
  {
    paragraph: `I have had the pleasure of knowing Dusty for some time now, and I can confidently say that he embodies the qualities of integrity, fairness, and a deep commitment to hard work. He consistently demonstrates honesty in all his dealings, both personal and professional. His ethical standards are unwavering, making him a trusted individual in any setting. He approaches challenges with grace and a steadfast belief in doing what is right, even when it is difficult. His character and values shine through in everything he does, making him an asset to any community or individual fortunate enough to have them for your pool needs.`,
    author: 'Bruce, C.'
  },
  {
    paragraph: `I’ve known Dusty for many years, and he is a businessman with integrity. He has always put people first.`,
    author: 'Billy, S.'
  },
  {
    paragraph: `The owner is super nice, overly honest, and easy to want to do business with. He treats you like family and wants to exceed your expectations. I highly recommend this company because the owner understands service is #1.`,
    author: 'Chad, A.'
  },
  {
    paragraph: `Great attention to details, took amazing care to get our pool filter working and now clear water! Communication throughout the process was remarkable.`,
    author: 'Denise, H.'
  }
];

export { CAROUSEL_CONTENT };
