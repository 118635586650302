import { BackgroundImage, Container, ServicePlans } from '../../components';
import { aboutUs as aboutUsAssets } from '../../assets';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid2,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { CONTENT, FEATURES } from '../../components/ServicePlans/utils';
import { Close } from '@mui/icons-material';

const PLANS = [
  {
    id: 'bronze',
    heading: 'Bronze Plan',
    tagLine: 'Chemical Only Maintenance Plan',
    caption: 'Great plan for basic pool care need',
    features: CONTENT[0].content
      .map((val, index) => (val === 1 ? FEATURES[index] : null))
      .filter((item) => !!item),
    paragraph: [
      `This plan offers an economical solution for your pool
      maintenance without compromising on quality. While this package does
      not include preferred service appointments, being a valued maintenance
      customer does grant you an enhanced priority status. This means that if
      you need repair work, we will make every effort to advance your
      appointment schedule.`,
      `Rest assured, the level of customer service and the frequency of routine
      reporting you have come to expect remains unchanged. You can have
      confidence in the knowledge that your pool is receiving the diligent care
      it requires, from chemical balance to filtration management. With the
      Bronze Plan, affordability meets our steadfast commitment to your
      pool's wellbeing.`,
      `While we realize our fee's may not fit each budget, our plans do
      include exclusive support from our Service and Construction
      Departments, which does add value when needed.`
    ]
  },
  {
    id: 'gold',
    heading: 'Gold Plan',
    tagLine: 'Full Service Maintenance Plan',
    caption: 'Does not include free filter clean',
    features: CONTENT[1].content
      .map((val, index) => (val === 1 ? FEATURES[index] : null))
      .filter((item) => !!item),
    paragraph: [
      `Our Gold Plan ensures you receive preferential treatment for
      scheduling service appointments, particularly for repairs & services
      that fall outside routine maintenance. You can count on expedited
      attention, bypassing the lengthy wait times that often occur during
      peak seasons. Rest assured, even when demand for service repairs
      reaches its zenith, your needs will be addressed swiftly & with the
      priority you deserve.`,
      `The Gold Plan is great for newer pools (excluding first year pools)
      where your warranty covers equipment repairs and filter cleans only
      require once a year cleaning. This plan can increase over average
      pricing due to foliage and/or age of equipment, however this plan
      insures weekly vacumning and brushing of your pool - two essential
      components that are not included in our Bronze Plan`
    ]
  },
  {
    id: 'platinum',
    heading: 'Platinum Plan',
    tagLine: 'Full Service Plus Maintenance Plan',
    caption: 'Weekly route consist of the following:',
    features: CONTENT[2].content
      .map((val, index) => (val === 1 ? FEATURES[index] : null))
      .filter((item) => !!item),
    paragraph: [
      `The Platinum Plan represents the pinnacle of our full-service
      offerings, It embodies the essence of a concierge-style approach. As a
      Platinum client, you will enjoy priority routing for our technicians,
      ensuring that your service needs are addressed promptly and efficiently.`,
      `Moreover, any repair requirements will be fast-tracked, underscoring
      our commitment to expedient and exceptional care. See condition of
      sales for more information. Giving you piece of mind for a one call
      approach for anthing related to your pool - hency our name Blue Sage
      Pool Solutions.`,
      `Consider the Platinum Plan as our ‘White Glove’ service tier—where
      every detail is handled with the utmost attention and precision. We
      aim to be your singular resource for all pool maintenance needs,
      streamlining service requests with just one call.`
    ]
  }
];

export const Plans = () => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ my: { xs: 4, sm: 6, md: 10 } }}>
        <Typography variant="h3" sx={{ mb: 4 }} textAlign="center">
          Our packages
        </Typography>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{ textTransform: 'uppercase' }}
        >
          All Pools are not created equal - Foliage, Age of equipment, Size of
          Pool and more impact your price - Call for free quote.
        </Typography>
        <ServicePlans sx={{ mt: { xs: 2, sm: 3, md: 4 } }} />
        <Typography variant="h4" textAlign="center" sx={{ mt: 4 }}>
          Billing Policy
        </Typography>
        <Typography variant="paragraph">
          All plans are billed at the beginning of the month for services
          provided during that month. Payments must be received upon billing at
          the start of the month to avoid suspension of additional weekly
          services. Pricing varies based on pool size, equipment, and foliage.
        </Typography>
      </Container>
      <Button
        variant="contained"
        sx={{
          p: 1.5,
          position: 'fixed',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          top: '70%',
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
        onClick={() => setOpenDialog(true)}
      >
        Help me choose
      </Button>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        PaperProps={{ sx: { maxWidth: 'none' } }}
      >
        <Container sx={{ overflow: 'auto' }}>
          <Stack
            sx={{ p: { xs: 2, sm: 4, md: 6 }, position: 'relative' }}
            spacing={{ xs: 3, sm: 4, md: 5 }}
            alignItems="center"
          >
            <Tooltip arrow title="Close">
              <IconButton
                sx={{ position: 'absolute', right: 0 }}
                onClick={() => setOpenDialog(false)}
              >
                <Close color="error" />
              </IconButton>
            </Tooltip>
            {PLANS.map((plan, index) => (
              <>
                <Box key={plan.id}>
                  <Typography variant="h3" color="primary">
                    {plan.heading}
                  </Typography>
                  <Typography variant="h5">{plan.tagLine}</Typography>
                  <Typography variant="caption2">{plan.caption}</Typography>
                  <Grid2 container columnSpacing={2} sx={{ px: 2, my: 2 }}>
                    {plan.features?.map((feature, index) => (
                      <Grid2
                        key={`${plan.id}-feature${index}`}
                        size={{ xs: 12, sm: 6 }}
                      >
                        <Typography variant="paragraph" sx={{ m: 0 }}>
                          {feature}
                        </Typography>
                      </Grid2>
                    ))}
                  </Grid2>
                  {plan.paragraph?.map((para, index) => (
                    <Typography
                      key={`${plan.id}-para-${index}`}
                      variant="paragraph"
                    >
                      {para}
                    </Typography>
                  ))}
                </Box>
                {index < PLANS.length - 1 && (
                  <Divider
                    sx={{
                      borderColor: 'primary.main',
                      width: '50%'
                    }}
                  />
                )}
              </>
            ))}
          </Stack>
        </Container>
      </Dialog>
    </>
  );
};
