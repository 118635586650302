import { Box } from '@mui/material';
import { FormProvider as Form } from 'react-hook-form';

export const FormProvider = ({ methods, onSubmit, children, sx }) => (
  <Form {...methods}>
    <Box component="form" sx={sx} onSubmit={onSubmit}>
      {children}
    </Box>
  </Form>
);
