const MuiAppBar = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      fontFamily: 'Fjalla One'
    }
  }
};

export default MuiAppBar;
