import { BackgroundImage, Container, Image } from '../../components';
import {
  aboutUs as aboutUsAssets,
  contacts as contactAssets
} from '../../assets';
import { Box, Button, Grid2, Stack, Typography } from '@mui/material';
import {
  HomeOutlined,
  MailOutline,
  SmartphoneOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const Contact = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="contained"
        onClick={() => navigate('/schedule-now')}
        sx={{
          p: 1.5,
          position: 'fixed',
          zIndex: '9999',
          top: '70%',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
      >
        Schedule Now
      </Button>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ my: { xs: 3, sm: 6, md: 9 } }}>
        <Grid2 container spacing={{ xs: 2, sm: 4, md: 6 }}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="caption">Contact us</Typography>
            <Typography variant="h3" sx={{ my: 4 }}>
              We want to hear from you!
            </Typography>
            <Typography variant="paragraph">
              Your Privacy is important to us - Blue Sage Pool Solutions does
              not sell or distribute your information. All personal information
              collected is used exclusively by Blue Sage Pools and will not be
              shared or sold to third parties. Feel free to email, call, or fill
              out our contact form below, and one of our team members will
              promptly follow up to address your needs. We look forward to
              assisting you!
            </Typography>
            <Stack spacing={1} sx={{ mt: 3 }} alignItems="flex-start">
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <HomeOutlined fontSize="large" />
                <Box>
                  <Typography variant="h5">Address</Typography>
                  <Typography fontWeight={300} fontSize={{ xs: '1.4rem' }}>
                    Frisco, Texas 75033
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <MailOutline fontSize="large" />
                <Box>
                  <Typography variant="h5">Email</Typography>
                  <Typography
                    component="a"
                    href="mailto:info@BlueSagePools.com"
                    sx={{
                      fontWeight: 300,
                      fontSize: { xs: '1.4rem' },
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        color: 'cyan'
                      },
                      transition: 'all 0.2s linear'
                    }}
                  >
                    info@BlueSagePools.com
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <SmartphoneOutlined fontSize="large" />
                <Box>
                  <Typography variant="h5">Phone</Typography>
                  <Typography
                    component="a"
                    href="tel:4695272583"
                    sx={{
                      fontWeight: 300,
                      fontSize: { xs: '1.4rem' },
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        color: 'cyan'
                      },
                      transition: 'all 0.2s linear'
                    }}
                  >
                    469-527-2583
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Image src={contactAssets.map} alt="Blue Sage Pools" />
          </Grid2>
          <Grid2 size={12} textAlign="center">
            <Button
              sx={{ py: 3, px: 4 }}
              variant="contained"
              onClick={() => navigate('/schedule-now')}
            >
              Contact Us
            </Button>
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
};
