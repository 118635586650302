import {
  BackgroundImage,
  MultiCheckbox,
  Container,
  FormProvider,
  TextField,
  Radiobox
} from '../../components';
import { aboutUs as aboutUsAssets } from '../../assets';
import { useForm } from 'react-hook-form';
import { Box, Button, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const ScheduleNow = () => {
  const navigate = useNavigate();

  const [showThankyou, setShowThankyou] = useState(false);

  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        street: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        zipcode: Yup.number().required('Required'),
        phone: Yup.string()
          .matches(
            /^\+?\d{1,3}?[-.\s]?(\(?\d{1,4}?\)?[-.\s]?)?\d{2,9}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/,
            'Invalid Number'
          )
          .required('Required'),
        hearFrom: Yup.string().required('Required'),
        hasCurrentContract: Yup.bool().required('Required')
      })
    ),
    defaultValues: { interest: [] },
    mode: 'all'
  });

  const { handleSubmit } = methods;

  const onSubmitHandler = async (formValues) => {
    console.log({ formValues });
    const url = 'https://formkeep.com/f/6f93b5f1166a';
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(formValues),
      headers: {
        Accept: 'application/javascript',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
        console.log({ formResponse: data });
        setShowThankyou(true);
      })
      .catch((error) => {
        console.log({ formError: error });
      });
  };

  return (
    <>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Box sx={{ maxWidth: '55rem', mx: 'auto' }}>
          {showThankyou ? (
            <>
              <Typography variant="h3" sx={{ my: 4 }} textAlign="center">
                Thank you!
              </Typography>
              <Typography variant="paragraph" textAlign="center">
                We received your submission.
              </Typography>
              <Typography variant="paragraph" textAlign="center">
                We will get back to you shortly.
              </Typography>
              <Box textAlign="center">
                <Button variant="contained" onClick={() => navigate('/')}>
                  Go back to Home page
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h3" sx={{ my: 4 }} textAlign="center">
                How can we help you today
              </Typography>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSubmitHandler)}
                sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
              >
                <Stack spacing={2}>
                  <TextField name="firstName" label="First Name" required />
                  <TextField name="lastName" label="Last Name" required />
                  <TextField name="email" label="Email" required />
                  <TextField name="street" label="Street Address" required />
                  <TextField name="city" label="City" required />
                  <TextField
                    type="number"
                    name="zipcode"
                    label="Zip Code"
                    required
                  />
                  <TextField name="phone" label="Daytime phone" required />
                </Stack>
                <Box>
                  <Typography variant="h5" textAlign="center" gutterBottom>
                    What interest do you have?
                  </Typography>
                  <Box>
                    <MultiCheckbox
                      name="interest"
                      options={[
                        {
                          value: 'pool_service_and_maintenance',
                          label: 'Pool Service And Maintenance'
                        },
                        {
                          value: 'new_pool_construction',
                          label: 'New Pool Construction'
                        },
                        {
                          value: 'pool_renovation',
                          label: 'Pool Renovation'
                        },
                        {
                          value: 'outdoor_kitchen',
                          label: 'Outdoor Kitched'
                        },
                        {
                          value: 'landscape_design',
                          label: 'Landscape Design'
                        }
                      ]}
                    />
                  </Box>
                </Box>
                <TextField name="hearFrom" label="How Did You Hear About Us?" />
                <Radiobox
                  required
                  title="Do you currently have a pool maintenance contract?"
                  name="hasCurrentContract"
                  labels={[
                    { label: 'No', value: false },
                    { label: 'Yes', value: true }
                  ]}
                />
                <TextField name="message" label="Message" rows={10} multiline />
                <Box sx={{ textAlign: 'center' }}>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </FormProvider>
            </>
          )}
        </Box>
      </Container>
    </>
  );
};
