import { styled } from '@mui/material';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';

const StyledCarousel = styled(ReactCarousel)(({ theme }) => ({
  '& .carousel .control-prev.control-arrow:before': {
    borderRight: `8px solid ${theme.palette.carousel.arrow}`
  },
  '& .carousel .control-next.control-arrow:before': {
    borderLeft: `8px solid ${theme.palette.carousel.arrow}`
  }
}));

export const Carousel = ({ children, sx, ...props }) => {
  return (
    <StyledCarousel
      sx={sx}
      autoPlay
      interval={5000}
      infiniteLoop
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      {...props}
    >
      {children}
    </StyledCarousel>
  );
};
