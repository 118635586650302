import { BackgroundImage, Container, Image } from '../../components';
import {
  aboutUs as aboutUsAssets,
  gallery as galleryAssets,
  service as serviceAssets,
  icon as iconAssets,
  home as homeAssets
} from '../../assets';
import {
  Box,
  Button,
  Grid2,
  List,
  ListItem,
  Stack,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const Service = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="contained"
        onClick={() => navigate('/schedule-now')}
        sx={{
          p: 1.5,
          position: 'fixed',
          zIndex: '9999',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          top: '70%',
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
      >
        Schedule Now
      </Button>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Grid2 container spacing={4}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="caption">we know pools!</Typography>
            <Typography sx={{ my: 4 }} variant="h3">
              Weekly Pool Maintenance with Service Repair and Installation
            </Typography>
            <Typography variant="h5" sx={{ textTransform: 'uppercase', mb: 2 }}>
              Sign up for Weekly Maintenance and get discounted Service Rates
            </Typography>
            <Typography variant="paragraph">
              <strong>Service, Repair and Installation -</strong> Our Certified
              Service Technicians are dedicated to providing quality diagnostic,
              repair and installation of all pool equipment. You will be alerted
              when our team is in route, and you'll receive detailed reports on
              your pool's status and the services performed. Consistent
              communication is one of our core tenants, so you can trust us to
              manage your pool and keep you informed every step of the way.
            </Typography>
            <Typography variant="paragraph">
              <strong>Weekly Maintenance -</strong> We have tested and serviced
              tens of thousands of pools in the DFW area over the last two
              decades, ensuring our methods effectively prevent algae, staining,
              and other issues resulting from improper care. To learn more about
              our history and expertise,{' '}
              <strong>visit our "About Us" page.</strong>
            </Typography>
            <Typography variant="paragraph">
              <strong>Combine Weekly Maintenance with Service</strong> If you
              are signed up on our weekly Maintenance Plan, you will receive
              discounted labor rates* on any repair and in some cases, FREE
              LABOR* on some pool side repairs or installations. All plans
              receive priority scheduling. (*applies to Gold and Platinum Plans)
            </Typography>
            <Typography variant="paragraph">
              <strong>Full-Service Solution: </strong>Our Service Repair team,
              Maintenance Team and Construction Team regularly collaborates to
              ensure the pools we service operate smoothly. We are constantly
              reviewing each pool for signs that would create unexpected
              breakdowns and/or water clarity issues. By having our team on site
              weekly, we can help prevent unexpected breakdowns and costly
              repairs that tend to fall outside of most budgets.
            </Typography>
            <Typography variant="paragraph">
              <strong>Save Time, Save Money and Eliminate Frustration:</strong>{' '}
              Owning a pool and maintaining it yourself requires significant
              time and effort. Let us demonstrate how using Blue Sage Pool
              Solutions can save you money by having access to our Full-Service
              model.
            </Typography>
            <Typography variant="paragraph">
              <strong>
                Choose Blue Sage Pools today and experience the difference –
              </strong>
            </Typography>
            <Box textAlign="center">
              <Button
                sx={{ py: 3, px: 4 }}
                onClick={() => navigate('/about-us')}
                variant="contained"
              >
                Read More
              </Button>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: { xs: 2, sm: 4, md: 6 }
            }}
          >
            <Image
              src={serviceAssets.side1}
              alt="Info Graphic"
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto'
              }}
            />
            <Image
              src={serviceAssets.side2}
              alt="Info Graphic"
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto'
              }}
            />
            <Image
              src={serviceAssets.side3}
              alt="Info Graphic"
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto'
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
      <Box
        sx={{
          py: { xs: 5, sm: 10, md: 15 },
          backgroundColor: 'primary.main',
          color: 'common.white'
        }}
      >
        <Container sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{ my: 4 }} textAlign="center">
            Service Department
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={5}
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ my: { xs: 2, sm: 3, md: 4 } }}
          >
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.swimmingPool}
                alt="Pool"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                New Pools
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image src={iconAssets.park} alt="Park" sx={{ width: '6rem' }} />
              <Typography variant="caption2" color="common.white">
                Landscaping
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.maintenance}
                alt="Maintenance"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Repair
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.sunUmbrella}
                alt="Remodel"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Remodel
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={homeAssets.tileCleaning}
                alt="Products"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Tile Cleaning
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="h5" sx={{ my: 3 }} textAlign="center">
            Ful solution - Dedicated Service Repair Team
          </Typography>
          <Typography variant="paragraph">
            <strong>SERVICE REPAIR DEPARTMENT</strong> - Our service team are
            some of the bests in the business. Because of our decades of
            experience working with customers, we recognize that unexpected
            equipment failures often come unexpected and outside of a budget.
            That's why we always provide GOOD, BETTER, BEST estimates to
            accommodate all budgets.
          </Typography>
          <Typography variant="paragraph">
            An added benefit for our MAINTENANCE CUSOTMERS is a preferred
            scheduling, discounted labor (some cases Free-Labor) and
            preventative maintenance by keeping experienced eyes on your
            equipment weekly.
          </Typography>
          <Typography variant="paragraph">
            Our goal is to build long-term relationships with our customers.
          </Typography>
        </Container>
      </Box>
      <BackgroundImage src={galleryAssets.topImage} alt="Blue Sage Pools">
        <Box sx={{ backgroundColor: 'rgba(0,0,0,0.25)' }}>
          <Container
            sx={{
              py: { xs: 10, sm: 15, md: 20 }
            }}
          >
            <Typography
              variant="caption"
              color="common.white"
              sx={{
                '&::before': { backgroundColor: 'common.white' }
              }}
            >
              All pools are not the same
            </Typography>
          </Container>
        </Box>
      </BackgroundImage>
      <Container sx={{ my: { xs: 4, sm: 6, md: 10 } }}>
        <Typography variant="h3" sx={{ mb: 4 }} textAlign="center">
          Interested in financing?
        </Typography>
        <Typography variant="paragraph">
          Since 1979, Lyon Financial has worked as a specialist in unsecured
          pool financing solutions. Unlike many banks and credit unions, our
          experience is unique to swimming pools, not just any type of loan.
          Your call will always be answered by a live, knowledgeable
          representative, eager to offer solutions. We are proudly veteran owned
          and operated, with special military programs available as a small
          gesture of our immense appreciation for our country’s heroes. Partner
          with the industry’s leader to make your backyard dreams come true!
        </Typography>
        <Image
          sx={{ my: { xs: 2, sm: 4 }, maxWidth: '50rem', mx: 'auto' }}
          src={serviceAssets.lyonFinance}
          alt="Lyon Finance"
        />
        <Grid2 container spacing={4} sx={{ mt: { xs: 2, sm: 4, md: 6 } }}>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Image
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '80%',
                maxWidth: '50rem',
                mx: 'auto'
              }}
              src={galleryAssets.geometric3}
              alt="Outdoor pool"
            />
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <List>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">01.</Typography>
                <Typography variant="caption2" color="common.black">
                  Low fixed rates
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">02.</Typography>
                <Typography variant="caption2" color="common.black">
                  Loans up to $200,000‡
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">03.</Typography>
                <Typography variant="caption2" color="common.black">
                  Terms up to 30 years‡
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">04.</Typography>
                <Typography variant="caption2" color="common.black">
                  Special military programs available#
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">05.</Typography>
                <Typography variant="caption2" color="common.black">
                  No prepayment penalties
                </Typography>
              </ListItem>
              <ListItem sx={{ gap: 1 }}>
                <Typography variant="caption2">06.</Typography>
                <Typography variant="caption2" color="common.black">
                  No equity or appraisals required
                </Typography>
              </ListItem>
            </List>
          </Grid2>
          <Grid2 size={12} textAlign="center">
            <Button
              variant="contained"
              disableElevation
              disableRipple
              sx={{ py: 3, px: 4 }}
              onClick={() =>
                window.open('https://www.lyonfinancial.net/apply/', '_blank')
              }
            >
              Qualify Now!
            </Button>
          </Grid2>
        </Grid2>
      </Container>
    </>
  );
};
