import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, useTheme } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { useScrollY } from '../../hooks';

export const ScrollTop = ({ children }) => {
  const theme = useTheme();
  const { pathname } = useLocation();

  const scrollY = useScrollY();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {children}
      <Button
        onClick={handleScrollToTop}
        disableElevation
        disableRipple
        variant="contained"
        sx={{
          backgroundColor: theme.palette.grey[500],
          position: 'fixed',
          bottom: '-5rem',
          right: '-5rem',
          ...(scrollY !== 0 && {
            bottom: '2rem',
            right: '2rem'
          }),
          transition: 'all 0.2s linear',
          '&:hover': {
            backgroundColor: theme.palette.olive
          }
        }}
      >
        <ArrowUpward />
      </Button>
    </>
  );
};
