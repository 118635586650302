import { grey } from '@mui/material/colors';

const typography = {
  fontFamily: 'Merriweather Sans',
  htmlFontSize: 10,
  h3: {
    fontFamily: 'Butler',
    textTransform: 'capitalize'
  },
  h5: {
    fontFamily: 'Fjalla One',
    letterSpacing: '2.2px'
  },
  caption: {
    textTransform: 'uppercase',
    fontFamily: 'Fjalla One',
    letterSpacing: '1.5px',
    color: grey[500],
    position: 'relative',
    paddingLeft: '10rem',
    '&::before': {
      content: '""',
      display: 'block',
      width: '7.2rem',
      height: '2px',
      backgroundColor: grey[500],
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)'
    }
  },
  caption2: {
    textTransform: 'uppercase',
    fontFamily: 'Fjalla One',
    letterSpacing: '1.5px',
    color: grey[500]
  },
  paragraph: {
    fontWeight: 300,
    lineHeight: '3rem',
    textAlign: 'justify',
    marginBottom: '2rem'
  }
};

export default typography;
