import { Controller, useFormContext } from 'react-hook-form';
import { TextField as MuiTextField, Stack, Typography } from '@mui/material';

export const TextField = ({ name, containerSX, label, required, ...props }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field, formState: { errors } }) => (
        <Stack spacing={0.5} sx={containerSX}>
          <Typography variant="caption2" color="primary.main" sx={{ m: 0 }}>
            {`${label}${required ? ' *' : ''}`}
          </Typography>
          <MuiTextField
            size="small"
            error={!!errors[name]}
            {...field}
            {...props}
            fullWidth
          />
          <Typography variant="caption2" color="error">
            {errors[name]?.message?.toString() || ''}
          </Typography>
        </Stack>
      )}
    />
  );
};
