import {
  Container as MuiContainer,
  Grid2,
  Typography,
  Stack,
  IconButton,
  Tooltip,
  Box
} from '@mui/material';
import {
  Call,
  Facebook,
  Google,
  HomeOutlined,
  Instagram,
  MailOutline,
  SmartphoneOutlined,
  YouTube
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Container, Image, Link } from '../../components';
import { aboutUs as aboutUsAssets, footer as footerAssets } from '../../assets';

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <MuiContainer
      component="footer"
      maxWidth="none"
      sx={{
        borderTop: '2px solid',
        borderColor: 'primary.main'
      }}
    >
      <Container>
        <Grid2
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 size={{ xs: 12, sm: 6, md: 4 }} sx={{ mt: 4 }}>
            <Image src={aboutUsAssets.weKnowPools} alt="We Know Pools" />
            <Stack
              spacing={1}
              sx={{
                mt: 3,
                width: { xs: 'min-content', sm: '100%' },
                mx: 'auto'
              }}
              alignItems="flex-start"
            >
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <HomeOutlined fontSize="small" />
                <Typography fontWeight={300} fontSize={{ xs: '1.4rem' }}>
                  Frisco, Texas 75033
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <MailOutline fontSize="small" />
                <Typography
                  component="a"
                  href="mailto:info@BlueSagePools.com"
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: '1.4rem' },
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                      color: 'grey.400'
                    },
                    transition: 'all 0.2s linear'
                  }}
                >
                  info@BlueSagePools.com
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <SmartphoneOutlined fontSize="small" />
                <Typography
                  component="a"
                  href="tel:4695272583"
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: '1.4rem' },
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                      color: 'grey.400'
                    },
                    transition: 'all 0.2s linear'
                  }}
                >
                  469-527-2583
                </Typography>
              </Box>
            </Stack>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
            <Image
              sx={{ maxWidth: { xs: '15rem', sm: 'none' }, mx: 'auto' }}
              src={footerAssets.yearExperience}
              alt="55 Years of Experience"
            />
          </Grid2>
          <Grid2
            size={12}
            sx={{ borderTop: '4px solid', borderColor: 'primary.main', mb: 5 }}
          >
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent={{ xs: 'center', sm: 'space-between' }}
              alignItems="center"
              sx={{ my: 2 }}
            >
              <Typography
                sx={{ display: 'flex', gap: 0.75, justifyContent: 'center' }}
                fontWeight={300}
              >
                <Link
                  text="Blue Sage Pools"
                  color="primary.main"
                  to="/"
                  sx={{
                    '&:hover': {
                      color: 'grey.400'
                    },
                    transition: 'all 0.2s linear'
                  }}
                />{' '}
                © 2024. All rights reserved.
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Tooltip arrow title="Facebook">
                  <IconButton
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com/profile.php?id=61563326453728&mibextid=LQQJ4d',
                        '_blank'
                      )
                    }
                  >
                    <Facebook color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Instagram">
                  <IconButton
                    onClick={() =>
                      window.open(
                        'https://www.instagram.com/bluesagepoolssolutions',
                        '_blank'
                      )
                    }
                  >
                    <Instagram color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Google Business Page">
                  <IconButton
                    onClick={() =>
                      window.open('https://g.co/kgs/k7n1eNT', '_blank')
                    }
                  >
                    <Google color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Youtube">
                  <IconButton
                    onClick={() =>
                      window.open(
                        'https://www.youtube.com/@BlueSagePoolsSolutions',
                        '_blank'
                      )
                    }
                  >
                    <YouTube color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Contact Us">
                  <IconButton onClick={() => navigate('contact')}>
                    <Call color="primary" fontSize="large" />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid2>
        </Grid2>
      </Container>
    </MuiContainer>
  );
};
