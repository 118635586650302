const CONTENT = [
  {
    id: 'bronze',
    heading: 'Bronze',
    content: [1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1]
  },
  {
    id: 'gold',
    heading: 'Gold',
    content: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 0, 0, 0, 1]
  },
  {
    id: 'platinum',
    heading: 'Platinum',
    content: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
  }
];

const FEATURES = [
  'Weekly Service - Billed monthly',
  'Weekly Surface and Basket Cleaning',
  'Check and Balance Core Chemicals',
  'Visual Inspect Pump/Filter Performance',
  'Alert if concerns arise',
  'Priority Service Repair',
  'Free Pool Cleaner Repair',
  'Backwash DE Sand Filter As Needed',
  '20% Off Filter Cleans',
  '20% off PoolRX',
  'Vacuum Pool if needed',
  'FREE FILTER CLEAN - ($149 value)',
  '20% Off PoolRX - ($60 value)',
  'FREE SERVICE CALLS - ($99 value)',
  'DISCOUNTED Service Repair Fees',
  'VIP Service Scheduling - (Priceless)',
  'Yard Services'
];

const PLANS = CONTENT.map(({ heading }) => heading);

export { CONTENT, FEATURES, PLANS };
