const palette = {
  primary: {
    main: '#002664'
  },
  common: {
    black: '#000',
    white: '#FFF'
  },
  cyan: 'rgb(37, 180, 207)',
  olive: '#c9d651',
  divider: '#F7F7F7',
  carousel: {
    arrow: '#002264'
  },
  bronze: '#CE8946',
  gold: '#EFBF04',
  platinum: '#D9D9D9'
};

export default palette;
