import { createBrowserRouter } from 'react-router-dom';
import { Layout } from '../components';
import * as Pages from '../pages';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Pages.Home />
      },
      {
        path: 'gallery',
        element: <Pages.Gallery />
      },
      {
        path: 'about-us',
        element: <Pages.AboutUs />
      },
      {
        path: 'service',
        element: <Pages.Service />
      },
      {
        path: 'plans',
        element: <Pages.Plans />
      },
      {
        path: 'contact',
        element: <Pages.Contact />
      },
      {
        path: 'schedule-now',
        element: <Pages.ScheduleNow />
      },
      {
        path: 'forms',
        children: [
          {
            path: 'inspection',
            element: <Pages.Inspection />
          }
        ]
      },
      {
        path: '*',
        element: <Pages.NotFound />
      }
    ]
  }
]);

export default router;
