import palette from './palette';

const MuiButton = {
  styleOverrides: {
    root: {
      letterSpacing: '1.5px',
      '&:hover': {
        backgroundColor: palette.cyan,
        color: palette.common.white
      }
    }
  }
};

export default MuiButton;
