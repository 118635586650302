import { Box, styled } from '@mui/material';

const BoxStyled = styled(Box)(() => ({
  '& > img': {
    height: '100%',
    width: '100%'
  }
}));

export const Image = ({ src, alt, sx }) => {
  return (
    <BoxStyled sx={sx}>
      <img src={src} alt={alt} />
    </BoxStyled>
  );
};

export const BackgroundImage = ({ src, sx, children }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        ...sx
      }}
    >
      {children}
    </Box>
  );
};
