import {
  Table,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  TableBody
} from '@mui/material';
import { CONTENT, FEATURES, PLANS } from './utils';

const TableCell = styled(MuiTableCell)(() => ({
  border: '1px solid'
}));

export const ServicePlans = ({ sx }) => {
  return (
    <Table size="small" sx={sx}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
            <Typography
              variant="caption2"
              textAlign="center"
              color="common.black"
              sx={{ display: 'block' }}
            >
              Plan Comparison
            </Typography>
          </TableCell>
          <TableCell sx={{ backgroundColor: '#0078C1' }}>
            <Typography
              sx={{
                display: 'block',
                color: 'common.white',
                textAlign: 'center'
              }}
              variant="caption2"
            >
              Essential Plan
            </Typography>
          </TableCell>
          <TableCell colSpan={2} sx={{ backgroundColor: '#4C9F2D' }}>
            <Typography
              sx={{
                display: 'block',
                color: 'common.white',
                textAlign: 'center'
              }}
              variant="caption2"
            >
              Complete Plan
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
            <Typography variant="h5" textAlign="center">
              Services
            </Typography>
          </TableCell>
          {PLANS.map((plan) => (
            <TableCell key={plan}>
              <Typography variant="h5">{plan}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {FEATURES.map((feature, index) => (
          <>
            <TableRow
              key={feature}
              sx={{
                display: { xs: 'table-row', sm: 'none' },
                backgroundColor: 'grey.300'
              }}
            >
              <TableCell colSpan={3}>
                <Typography textAlign="center">{feature}</Typography>
              </TableCell>
            </TableRow>
            <TableRow
              key={`${feature}-row`}
              sx={{
                backgroundColor: {
                  xs: 'common.white',
                  sm: index % 2 ? 'grey.300' : 'common.white'
                }
              }}
            >
              <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                <Typography>{feature}</Typography>
              </TableCell>
              {PLANS.map((plan, pi) => (
                <TableCell key={`${feature}-${plan}-${pi}`}>
                  <Typography
                    textAlign="center"
                    sx={{ color: pi === 0 ? '#0078C1' : '#4C9F2D' }}
                  >
                    {CONTENT[pi].content[index] ? '✔' : ''}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </>
        ))}
      </TableBody>
    </Table>
  );
};
