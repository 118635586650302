import { Container as MuiContainer, styled } from '@mui/material';

const StyledMuiContainer = styled(MuiContainer)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: theme.breakpoints.values['sm'] + (theme.breakpoints.unit || 'px')
  },
  [theme.breakpoints.up('md')]: {
    width: theme.breakpoints.values['md'] + (theme.breakpoints.unit || 'px')
  },
  [theme.breakpoints.up('lg')]: {
    width: theme.breakpoints.values['lg'] + (theme.breakpoints.unit || 'px')
  },
  [theme.breakpoints.up('xl')]: {
    width: theme.breakpoints.values['xl'] + (theme.breakpoints.unit || 'px')
  }
}));

export const Container = ({ children, ...props }) => (
  <StyledMuiContainer {...props}>{children}</StyledMuiContainer>
);
