import {
  Box,
  Button,
  Divider,
  Grid2,
  List,
  ListItem,
  Typography,
  Stack
} from '@mui/material';
import { BackgroundImage, Carousel, Container, Image } from '../../components';
import { aboutUs as aboutUsAssets } from '../../assets';
import { CAROUSEL_CONTENT } from './utils';
import { useNavigate } from 'react-router-dom';

export const AboutUs = () => {
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="contained"
        onClick={() => navigate('/schedule-now')}
        sx={{
          p: 1.5,
          position: 'fixed',
          zIndex: '9999',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          top: '70%',
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
      >
        Schedule Now
      </Button>
      <BackgroundImage
        sx={{ height: '45vh' }}
        src={aboutUsAssets.blueSagePoolCover}
        alt="Blue Sage Pools"
      />
      <Container sx={{ mt: { xs: 5, sm: 10, md: 15 } }}>
        <Grid2 container spacing={4}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="caption">we know pools!</Typography>
            <Typography sx={{ my: 4 }} variant="h3">
              decades of combined experience
            </Typography>
            <Typography variant="h5" sx={{ textTransform: 'uppercase', mb: 2 }}>
              over 55 years of pool supply, maintenance, service, and
              construction experience.
            </Typography>
            <Typography variant="paragraph">
              <strong>MEET THE OWNERS</strong> – Dusty Hodge and Don Blake -----
              Our journey in the pool industry began as early as 1985 in Austin,
              TX. We gained firsthand experience in pool construction and joined
              Leslie’s Pool Supplies (Dusty in 1990, Don in 2001). Over our
              long-tenured career at Leslie’s, spanning over 30 years, our team
              was instrumental in building over hundreds of retail locations,
              infusing dozens of Commercial Service Centers, employing and
              training 1000’s of employees and ended our career at the Peak of
              Leslie’s becoming a Billion Dollar Business.
            </Typography>
            <Typography variant="paragraph">
              We both kept our roots in N. Texas and spent most of our career in
              the great State of Texas, along with the Central and Southern
              parts of the US. We have personally held various roles in the
              swimming pool industry including Pool Tech, Retail Store Manager,
              District Manager, Senior VP, Executive VP, and Chief Operating
              Officer. We grew up in and haven’t left the swimming pool
              industry, and now bring our experience to Blue Sage Pool
              Solutions, LLC.
            </Typography>
            <Typography variant="paragraph">
              Our team at Blue Sage Pools, shares a similar career path and has
              a combined tenure of over 55+ years in the swimming pool business.
              Many worked for years at Leslie’s alongside us and also bring
              decades of administrative and financial expertise to Blue Sage
              Pools. We have been engaged in a variety of projects, ranging from
              multi-million-dollar backyard transformations to standard
              homeowner pool installations throughout the area.
            </Typography>
            <Typography variant="paragraph">
              We understand the needs of our customers through firsthand
              experience. We've helped many new and existing pool owners
              navigate the process of pool ownership, offering services in
              design, maintenance, remodeling, and repairs.
            </Typography>
            <Typography variant="paragraph">
              If you live in the DFW area and own a swimming pool, chances are
              you’ve encountered a member of our team. Each one of our
              leadership team has worked in various cities around DFW including
              <strong>
                Frisco, Proper, Flower Mound, Lewisville, Southlake, Keller, N.
                Dallas, N. Fort Worth, McKinney, Plano. All areas we still serve
                today!!
              </strong>
            </Typography>
            <Typography variant="paragraph">
              Blue Sage Pools is built on honesty, ethical practices, and strong
              faith. We know what it takes to own, build, and maintain a
              swimming pool in Texas. Whether you're an existing pool owner or
              looking to become one, we would be honored to earn your trust,
              partnership and business.
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Image
              src={aboutUsAssets.infoGraphic}
              alt="Info Graphic"
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto'
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
      <Container sx={{ mt: { xs: 5, sm: 10, md: 15 } }}>
        <Grid2 container spacing={4}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="caption">about our company</Typography>
            <Typography sx={{ my: 4 }} variant="h3">
              serving your area
            </Typography>
            <Typography variant="paragraph">
              The cities and towns listed here are not only where we live but
              also areas we currently serve. We have tested thousands of
              swimming pools, educated homeowners on pool maintenance, completed
              numerous remodeling projects, built pools and gazebos, and
              repaired equipment. At Blue Sage Pools, we don't just know pools –
              we've been working in your community with you and your neighbors
              for decades.
            </Typography>
            <Typography variant="paragraph">
              If you live in this area, we would be honored to have the
              opportunity to help with your swimming pool or backyard needs. We
              excel in delivering top-notch service and expertise. Meet with us
              and experience firsthand our commitment to excellence, rooted in
              decades of experience. We know pools! Let us demonstrate our
              dedication to superior service and quality.
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Image
              src={aboutUsAssets.cityOfLogos}
              alt="Info Graphic"
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                maxHeight: '55rem',
                position: 'relative',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                '& > img': { width: 'auto' }
              }}
            />
          </Grid2>
        </Grid2>
      </Container>
      <Box
        sx={{
          mt: { xs: 5, sm: 10, md: 15 },
          backgroundImage: `url(${aboutUsAssets.background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <Box
          sx={{
            backdropFilter: 'blur(1rem)',
            backgroundColor: 'rgba(0,0,0,0.15)'
          }}
        >
          <Container
            sx={{
              py: { xs: 5, sm: 10, md: 15 }
            }}
          >
            <Grid2 container spacing={4}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Typography
                  variant="caption"
                  color="common.white"
                  sx={{ '&::before': { backgroundColor: 'common.white' } }}
                >
                  Blue Sage Pools
                </Typography>
                <Typography sx={{ my: 4 }} variant="h3" color="common.white">
                  top reasons to contact us today!
                </Typography>
                <Typography color="common.white">
                  "It starts with over 55 years of combined experience in DFW
                  and The State of Texas"
                </Typography>
                <Typography
                  color="common.white"
                  variant="h5"
                  textTransform="uppercase"
                  sx={{ mt: 4 }}
                >
                  We know pools!
                </Typography>
                <Button
                  fullWidth
                  sx={{ py: 2, px: 3, mt: 4 }}
                  variant="contained"
                  onClick={() => navigate('/service')}
                >
                  Services
                </Button>
              </Grid2>
              <Grid2
                size={{ xs: 12, sm: 6 }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <List
                  sx={{
                    backgroundColor: 'common.white',
                    width: { xs: '100%', sm: '90%' },
                    px: 2,
                    py: 4
                  }}
                >
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>01.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Expert</strong> Pool Maintenance & Repair
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>02.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Experienced</strong> Service Department
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>03.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Personalized</strong> & Reliable Service
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>04.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Comprehensive</strong> Construction Services
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>05.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Valuable</strong> Feedback & Recommendations
                    </Typography>
                  </ListItem>
                </List>
              </Grid2>
            </Grid2>
          </Container>
        </Box>
      </Box>
      <Container sx={{ mt: { xs: 5, sm: 10, md: 15 }, mb: 5 }}>
        <Typography variant="caption">facts about us</Typography>
        <Typography variant="h3" sx={{ my: 4 }}>
          Why People Choose Us
        </Typography>
        <Grid2 container spacing={5}>
          <Grid2 size={{ xs: 12, sm: 4 }}>
            <Image src={aboutUsAssets.blueSagePoolLogo} alt="Blue Sage Pools" />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 8 }}>
            <List>
              <ListItem>
                <Typography textAlign="justify">
                  <strong>Unmatched Expertise:</strong> with over 55 years of
                  combined experience in the pool industry, we bring
                  unparalleled knowledge and experience to every project.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography textAlign="justify">
                  <strong>Trusted Legacy:</strong> For decades our team has
                  worked with the best executives and experts in the business,
                  which leverages the best advice, expertise, and confidence in
                  the industry for our customers.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography textAlign="justify">
                  <strong>Quality Craftsmanship:</strong> Our commitment to
                  excellence guarantees top-quality results, whether it's
                  routine maintenance or a full-scale construction project.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography textAlign="justify">
                  <strong>Reliable and Trustworthy:</strong> As a family owned
                  and operated business, we prioritize trust, ethical practices
                  and honesty while building long-lasting relationships with our
                  clients.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography textAlign="justify">
                  <strong>Comprehensive Solutions:</strong> From maintenance and
                  repairs to custom builds and upgrades, we offer a complete
                  range of pool services.
                </Typography>
              </ListItem>
            </List>
            <Image src={aboutUsAssets.weKnowPools} alt="Blue Sage Pools" />
          </Grid2>
          <Grid2 size={12} textAlign="center">
            <Button
              variant="contained"
              disableElevation
              disableRipple
              sx={{ py: 3, px: 4 }}
            >
              services
            </Button>
          </Grid2>
        </Grid2>
      </Container>
      <Divider
        sx={{
          bgcolor: 'divider',
          height: { xs: '12rem', sm: '15rem', md: '20rem', lg: '25rem' }
        }}
      />
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Typography variant="h3" textAlign="center" sx={{ my: 3 }}>
          What people say
        </Typography>
        <Carousel showArrows>
          {CAROUSEL_CONTENT.map(({ paragraph, author }) => (
            <Stack
              key={author}
              sx={{ height: '100%' }}
              spacing={{ xs: 2, sm: 4 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" color="grey.400">
                "
              </Typography>
              <Typography
                fontStyle="italic"
                fontWeight={300}
                fontSize={{ xs: '1.4rem', sm: '2rem' }}
                width="max(30rem, 90%)"
              >
                {paragraph}
              </Typography>
              <Typography variant="caption2" component="p">
                {author.split(', ').map((val, index) => {
                  if (index === 0)
                    return (
                      <Typography
                        key={`key-${index}`}
                        variant="caption2"
                        color="common.black"
                      >
                        {`${val}, `}
                      </Typography>
                    );
                  return index === 1 ? val : `, ${val}`;
                })}
              </Typography>
            </Stack>
          ))}
        </Carousel>
      </Container>
    </>
  );
};
