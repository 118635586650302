import breakpoints from './breakpoints';

const MuiTypography = {
  defaultProps: {
    variantMapping: {
      paragraph: 'p'
    }
  },
  styleOverrides: {
    caption: {
      [`@media (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '1.4rem'
      }
    },
    h3: {
      [`@media (min-width: ${breakpoints.values.sm}px)`]: {
        fontSize: '3rem'
      },
      [`@media (min-width: ${breakpoints.values.lg}px)`]: {
        fontSize: '5rem'
      }
    }
  }
};

export default MuiTypography;
