const BUTTONS = [
  {
    id: 'outdoor',
    imageCount: 10,
    label: 'Outdoor living'
  },
  {
    id: 'geometric',
    imageCount: 15,
    label: 'Geometric / Formal'
  },
  {
    id: 'freeform',
    imageCount: 9,
    label: 'Freeform / natural'
  },
  {
    id: 'construction',
    imageCount: 10,
    label: 'Construction'
  }
];

export { BUTTONS };
