import { Outlet } from 'react-router-dom';
import { Footer, Navbar, ScrollTop } from '../../components';

export const Layout = () => {
  return (
    <ScrollTop>
      <Navbar />
      <Outlet />
      <Footer />
    </ScrollTop>
  );
};
