import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  return (
    <Container
      sx={{
        textAlign: 'center',
        marginTop: '100px'
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '72px',
          marginBottom: '24px'
        }}
      >
        404
      </Typography>
      <Typography
        variant="h6"
        sx={{
          marginBottom: '16px'
        }}
      >
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        sx={{
          marginTop: '16px'
        }}
      >
        Go back to Home
      </Button>
    </Container>
  );
};
