import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const SelectTag = ({
  name,
  menus,
  sx,
  label,
  disabled,
  allowClear,
  required,
  defaultValue = '',
  size = 'small',
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, formState: { errors } }) => (
        <Stack spacing={0.5}>
          <Typography variant="caption2" color="primary.main" sx={{ m: 0 }}>
            {`${label}${required ? ' *' : ''}`}
          </Typography>
          <Select size="small" {...field} error={!!errors?.[name]}>
            {menus.map((menu) => (
              <MenuItem key={menu.label} value={menu.label}>
                {menu.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    />
  );
};
