import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox as MuiCheckbox,
  Typography
} from '@mui/material';

export function MultiCheckbox({ name, options, title, required, ...other }) {
  const { control } = useFormContext();

  const onSelected = (option, field) =>
    field.value && field.value.includes(option)
      ? field.value.filter((value) => value !== option)
      : [...(field.value ? field.value : []), option];

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={options.map(({ value }) => value)}
      render={({ field, fieldState: { error } }) => (
        <>
          {title && (
            <FormLabel
              sx={(theme) => ({
                color: theme.palette.common.black,
                display: 'flex',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'flex-start'
                }
              })}
            >
              {title}
              {required && !title.includes(' *') && ' *'}
            </FormLabel>
          )}
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <MuiCheckbox
                    checked={
                      field?.value && field?.value?.includes(option.value)
                    }
                    onChange={() =>
                      field.onChange(onSelected(option.value, field))
                    }
                  />
                }
                label={option.label}
                {...other}
              />
            ))}
          </FormGroup>
          {!!error && error?.message && (
            <Typography variant="paragraph" color="error">
              {error?.message}
            </Typography>
          )}
        </>
      )}
    />
  );
}
