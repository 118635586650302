import { createTheme, responsiveFontSizes } from '@mui/material';
import typography from './typography';
import breakpoints from './breakpoints';
import MuiCssBaseline from './MuiCssBaseline';
import MuiAppBar from './MuiAppBar';
import MuiToolbar from './MuiToolbar';
import MuiButton from './MuiButton';
import MuiTypography from './MuiTypography';
import palette from './palette';

const theme = createTheme({
  breakpoints,
  typography,
  palette,
  components: {
    MuiCssBaseline,
    MuiAppBar,
    MuiToolbar,
    MuiButton,
    MuiTypography
  }
});

const responsiveFontSizeTheme = responsiveFontSizes(theme);
responsiveFontSizeTheme.typography.caption = {
  ...responsiveFontSizeTheme.typography.caption,
  [theme.breakpoints.up('lg')]: {
    fontSize: '2rem !important'
  }
};
console.log(responsiveFontSizeTheme);

export default responsiveFontSizeTheme;
