import {
  merriweatherSansBold,
  merriweatherSansLight,
  merriweatherSansMedium,
  merriweatherSansRegular,
  fjallaOneRegular,
  butlerBold
} from '../fonts';

const MuiCssBaseline = {
  styleOverrides: `
        * {
            box-sizing: border-box;
        }
        html {
            font-size: 62.5%;
        }
        body {
            height: 100vh;
        }
        @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 300;
            src: url(${merriweatherSansLight.default}) format('woff');
        }
        @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 400;
            src: url(${merriweatherSansRegular.default}) format('woff');
        }
        @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 500;
            src: url(${merriweatherSansMedium.default}) format('woff');
        }
        @font-face {
            font-family: 'Merriweather Sans';
            font-style: normal;
            font-weight: 700;
            src: url(${merriweatherSansBold.default}) format('woff');
        }
        @font-face {
            font-family: 'Fjalla One';
            font-style: normal;
            font-weight: 400;
            src: url(${fjallaOneRegular.default}) format('woff');
        }
        @font-face {
            font-family: 'Butler';
            font-style: normal;
            font-weight: 700;
            src: url(${butlerBold.default}) format('woff');
        }
    `
};

export default MuiCssBaseline;
