import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export const Radiobox = ({ name, required, title, labels, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      render={({ field }) => (
        <FormControl>
          <FormLabel id="radio-group">
            <Typography variant="caption2" color="primary.main">
              {required ? `${title} *` : title}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-group"
            {...field}
            {...props}
            name={name}
          >
            {labels.map(({ value, label }) => (
              <FormControlLabel
                value={value}
                label={label}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
